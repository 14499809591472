export interface ProjectLocalConfig {
  madId: number;
  isGit: boolean;
}

const defaultConfig: ProjectLocalConfig = {
  madId: null,
  isGit: false,
};

export function getProjectLocalConfig(project: string): ProjectLocalConfig {
  return projectsLocalConfig[project];
}

function extendDefaultConfig(
  config: Partial<ProjectLocalConfig> = {},
): ProjectLocalConfig {
  return {
    ...defaultConfig,
    ...config,
  };
}

const projectsLocalConfig: Record<string, ProjectLocalConfig> = {
  cp: extendDefaultConfig({
    madId: 98,
  }),
  dc: extendDefaultConfig({
    madId: 74,
    isGit: true,
  }),
  mc: extendDefaultConfig({
    madId: 73,
    isGit: true,
  }),
  rc: extendDefaultConfig({
    madId: 68,
  }),
  sg: extendDefaultConfig({
    madId: 182,
    isGit: true,
  }),
  pg: extendDefaultConfig({
    madId: 219,
    isGit: true,
  }),
  spr: extendDefaultConfig({
    madId: 222,
    isGit: true,
  }),
  wl: extendDefaultConfig({
    madId: 150,
    isGit: true,
  }),
  sem: extendDefaultConfig({
    madId: 252,
    isGit: true,
  }),
  bg: extendDefaultConfig({
    madId: 79,
    isGit: true,
  }),
  twd: extendDefaultConfig({
    madId: 323,
    isGit: true,
  }),
  demo: extendDefaultConfig({
    isGit: true,
  }),
  gp: extendDefaultConfig({
    madId: 337,
    isGit: true,
  }),
  zp: extendDefaultConfig({
    isGit: true,
  }),
  tr: extendDefaultConfig({
    madId: 355,
    isGit: true,
  }),
  thd: extendDefaultConfig({
    madId: 356,
    isGit: true,
  }),
};
export type ProjectIdentifier = keyof typeof projectsLocalConfig;
