export enum OverwriteService {
  BATTLE_VALIDATOR = 'battle_validator',
  //TBD: ALL backend Central services
}

export class Pod {
  endpoint: string;
  repository: string;
  project: string;
  cmbranch?: string;
  config_branch?: string;
  branch: string;
  commit: string;
  status_reason: string;
  facebook_app_id: string;
  facebook_app_secret: string;
  facebook_app_namespace: string;
  is_production: string;
  statics_server: string;
  localizations_server: string;
  expiration: number;
  timestamp?: number;
  user?: string;
  sync_backend?: boolean;
  overwrite: any;

  constructor(public identifier: string = '', public status: string = '') {}
}

export const CUSTOM_PROJECTS_DATA = new Map([
  ['sg', { testenv: true, chart: 'sg-dynenv' }],
  ['cp', { testenv: true, chart: 'cp-dynenv' }],
  ['rc', { testenv: true, chart: 'rc-dynenv' }],
  ['pg', { testenv: true, chart: 'pg-dynenv' }],
  ['spr', { testenv: true, chart: 'spr-dynenv' }],
  ['sem', { testenv: true, chart: 'sem-dynenv' }],
  ['wl', { testenv: true, chart: 'wl-dynenv' }],
  ['bg', { testenv: true, chart: 'bg-dynenv' }],
  ['dc', { testenv: true, chart: 'dc-dynenv' }],
  ['mc', { testenv: true, chart: 'mc-dynenv' }],
  ['twd', { testenv: true, chart: 'twd-dynenv' }],
  ['gp', { testenv: true, chart: 'gp-dynenv' }],
  ['zp', { testenv: true, chart: 'zp-dynenv' }],
  ['fm2', { testenv: true, chart: 'fm2-dynenv' }],
  ['tr', { testenv: true, chart: 'tr-dynenv' }],
  ['thd', { testenv: true, chart: 'thd-dynenv' }],
]);

export const ProjectOverwriteServices = new Map<string, OverwriteService[]>([
  ['sem', [OverwriteService.BATTLE_VALIDATOR]],
]);

export const PROJECT_HAS_PROXY = new Set([
  'sg',
  'cp',
  'rc',
  'sem',
  'wl',
  'dc',
  'mc',
  'twd',
  'tr',
  'thd',
]);
